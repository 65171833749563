import React from 'react';
import { IButton, IImage, ITextAlignment, IThemes } from "types/base";
import { Image } from "react-datocms";
import Heading from "components/elements/Heading";
import Paragraph from "components/elements/Paragraph";
import Button from "components/elements/Button";
import Link from "components/base/Link";
import cn from "classnames";
import { adjustAlignment, lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";
import CtaForm from "blocks/cta/CtaForm";
import Container from "components/core/Container";

export type IButtonList = {
    __typename: string,
    id: string,
    buttons: IButton[],
};

export type IForm = {
    __typename: string,
    id: string,
    placeholder: string,
    button: IButton[],
};

export type ICtaBlock = {
    id?: string,
    alignment: ITextAlignment,
    container: boolean,
    title: string,
    description: any,
    ctaForm?: IButtonList[] | IForm,
    image?: IImage,
    theme: IThemes,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    hasAwards: boolean
};

type Props = {
    container?: boolean;
};

export default function CtaBlock(props: ICtaBlock & Props) {
    const {
        title,
        description,
        alignment,
        image,
        container,
        lessSpaceTop,
        lessSpaceBottom,
        theme,
        hasAwards,
    } = props;
    const ctaForm = props.ctaForm ? props.ctaForm[0] : "";

    // There is always exact 1 ctaForm, but DatoCMS requires an array.
    const isButtonList = ctaForm.__typename == "ButtonListRecord";
    const isForm = ctaForm.__typename == "FormRecord";
    const hasImage = props.image != null;

    const centeredWithImage = alignment == "center" && hasImage;
    const centeredNoImage = alignment == "center" && !hasImage;
    const leftWithImage = alignment == "left" && hasImage;
    const leftNoImage = alignment == "left" && !hasImage;

    const leftWithImageContainerCn = cn(
        { "flex flex-col md:flex-row md:justify-between": leftWithImage },
    );

    const containerCn = cn(
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );

    const alignmentCn = cn(
        adjustAlignment(alignment),
        { "flex flex-col px-5 lg:px-0 py-8": centeredWithImage },
        { "flex flex-col py-10 lg:py-16": centeredNoImage },
        { "flex flex-col py-10 lg:pr-16 lg:w-7/12 lg:my-auto": leftWithImage },
        { "flex flex-col lg:flex-row justify-between px-6 lg:px-16": leftNoImage },
    );

    const headingCn = cn(
        { "text-center lg:mx-auto mb-2": centeredWithImage },
        { "text-center lg:mx-auto": props.alignment == "center" },
        { "text-left": props.alignment == "left" },
        "tracking-tight font-light"
    );

    const textColorParagraphCTA = cn(
        theme == "dark" && "text-primary-200",
        theme == 'gray' && 'text-gray-500');

    const paragraphCn = cn(
        "tracking-tight font-light",
        { "text-base lg:text-lg text-center lg:mx-auto": centeredWithImage },
        { "text-lg lg:text-xl text-center lg:mx-auto x-6 lg:px-0": centeredNoImage },
        { "text-lg lg:text-xl px-6 lg:px-16 text-left": leftWithImage },
        { "text-lg lg:text-xl text-left": leftNoImage },
        textColorParagraphCTA
    );

    const headingContainerCn = cn(
        { "mb-2 lg:mb-5": centeredWithImage },
        { "px-6 lg:px-0 mb-5 lg:mb-5": centeredNoImage },
        { "px-6 lg:px-16 mb-4 lg:mb-5": leftWithImage },
        { "pt-10 lg:py-16": leftNoImage },
    );

    const formContainerCn = cn(
        { "lg:mx-auto flex flex-col lg:flex-row gap-3": centeredWithImage },
        { "md:mx-auto flex flex-col md:flex-row gap-3 px-5 lg:px-0": centeredNoImage },
        { "flex flex-col md:flex-row flex-start gap-3 px-6 lg:px-16 mb-12 lg:mb-0": leftWithImage },
        { "flex flex-col md:flex-row lg:flex-end pb-10 lg:pt-16 lg:pb-24 gap-3": leftNoImage },
    );

    const ctaFormCn = cn(
        "flex bg-white rounded-xl md:w-96 lg:w-150",
        isForm && "border rounded-xl border-gray-100 lg:border-0",
    );

    return (
        <Container
            theme={props.theme}
            isRounded={container}
            className={containerCn}
            style={{ marginBottom: "-1px" }}
        >
            <div className={leftWithImageContainerCn}>
                <div className={alignmentCn}>
                    <div className={headingContainerCn}>
                        {(hasImage && props.alignment == "center") &&
                            <div className={"flex mb-6 lg:mb-8 justify-center"}>
                                {image && image.responsiveImage && (
                                    <Image data={image.responsiveImage}/>
                                )}
                            </div>
                        }

                        {leftNoImage &&
                            <div className={'mb-4 lg:mb-5'}>
                                <Heading
                                    theme={props.theme}
                                    className={headingCn}
                                    renderAs={"h2-consistent"}
                                >
                                    {title}
                                </Heading>
                            </div>
                        }

                        {leftWithImage &&
                            <Heading
                                theme={props.theme}
                                className={headingCn}
                                renderAs={"h2"}
                            >
                                {title}
                            </Heading>
                        }

                        {centeredWithImage &&
                            <Heading
                                theme={props.theme}
                                className={headingCn}
                                renderAs={"h5"}
                            >
                                {title}
                            </Heading>
                        }

                        {centeredNoImage &&
                            <Heading
                                theme={props.theme}
                                className={headingCn}
                                renderAs={"h2-cta"}
                            >
                                {title}
                            </Heading>
                        }
                        {leftNoImage &&
                            <div className={container ? "mb-6 lg:mb-8" : "mb-8 lg:mb-10"}>
                                <Paragraph
                                    className={paragraphCn}
                                    renderAs={"structuredText"}
                                    size={"large"}
                                    theme={props.theme}
                                    fontWeight={"light"}
                                >
                                    {description}
                                </Paragraph>
                            </div>
                        }

                    </div>

                    {centeredWithImage &&
                        <div className={"mb-2 lg:mb-8"}>
                            <Paragraph
                                className={"text-center text-lg text-primary-200"}
                                renderAs={"structuredText"}
                                size={"small"}
                                theme={props.theme}
                                fontWeight={"light"}
                            >
                                {description}
                            </Paragraph>
                        </div>
                    }

                    {centeredNoImage &&
                        <div className={container ? "px-6 lg:px-0 mb-6 lg:mb-8" : "mb-8 lg:mb-10"}>
                            <Paragraph
                                className={paragraphCn}
                                renderAs={"structuredText"}
                                size={"large"}
                                theme={props.theme}
                                fontWeight={"light"}
                            >
                                {description}
                            </Paragraph>
                        </div>
                    }

                    {leftWithImage &&
                        <div className={container ? "mb-6 lg:mb-8" : "mb-8 lg:mb-10"}>
                            <Paragraph
                                className={paragraphCn}
                                renderAs={"structuredText"}
                                size={"large"}
                                theme={props.theme}
                                fontWeight={"light"}
                            >
                                {description}

                            </Paragraph>
                        </div>
                    }

                    <div className={formContainerCn}>
                        {isButtonList && ctaForm.buttons.map((button: IButton) =>
                            <Link
                                key={button.id}
                                {...button.link[0]}
                                className={""}
                            >
                                <Button
                                    id={button.id}
                                    variant={button.variant}
                                    size={button.size}
                                    color={button.color}
                                    textColor={button.textColor}
                                    icon={button.icon}
                                    className={"flex"}
                                >
                                    {button.label}
                                </Button>
                            </Link>
                        )}

                        {isForm && (
                            <CtaForm
                                {...ctaForm}
                                className={ctaFormCn}
                            />
                        )}

                        {isForm && (
                            ctaForm.button.map((button: IButton) =>
                                <div
                                    key={button.id + button.label}
                                    className={"lg:hidden lg:h-0 block h-full"}
                                >
                                    <Link {...button.link[0]}>
                                        <Button
                                            id={button.id}
                                            variant={button.variant}
                                            size={button.size}
                                            color={button.color}
                                            textColor={button.textColor}
                                            icon={button.icon}
                                        >
                                            {button.label}
                                        </Button>
                                    </Link>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className={'flex'}>
                    {(hasImage && props.alignment == "left") &&
                        <div
                            className={`flex justify-center h-full ${hasAwards && 'my-auto pb-10 md:pb-0 px-6 md:pr-16'} w-full md:w-auto`}
                        >
                            {image && image.responsiveImage && (
                                <Image
                                    data={image.responsiveImage}
                                    pictureClassName={`rounded-tr-none md:rounded-tr-3xl rounded-br-3xl rounded-bl-3xl md:rounded-bl-none rounded-bl-3xl md:rounded-bl-none ${hasAwards ? 'object-contain' : 'object-none md:object-cover'}`}
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        </Container>
    );
}
