import React from "react";
import Container from "components/core/Container";
import { IThemes } from "types/base";
import PreHeading from "components/elements/PreHeading";
import FaqItem, { IFaqItem } from "./FaqItem";
import Paragraph from "components/elements/Paragraph";
import Text from "components/typography/Text";
import cn from "classnames";
import { lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "../../classes";

export type IFaq = {
    __typename?: string,
    id?: string,
    theme?: IThemes,
    lessSpaceTop?: boolean,
    lessSpaceBottom?: boolean,
    preHeading?: string,
    title?: string,
    description?: any,
    children?: number,
    faqItems?: IFaqItem[]
};

type Props = {
    className?: string,
};


export default function Faq(props: IFaq & Props) {
    const { faqItems, description, theme, lessSpaceTop, lessSpaceBottom } = props;

    const innerContainerCn = cn(
        'flex flex-col lg:flex-row gap-20',
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );

    const textContainerCn = cn(
        'flex flex-col shrink-0 w-full lg:w-112'
    );

    const faqContainerCn = cn(
        'flex flex-col border-t',
        'grow-1'
    );

    return (
        <Container className={innerContainerCn} theme={theme}>
            <div className={textContainerCn}>
                <PreHeading>
                    {props.preHeading}
                </PreHeading>
                <Text weight={'medium'} size={'4xl'} className={'mb-4 lg:mb-5'}>
                    {props.title}
                </Text>
                <Paragraph
                    renderAs={"structuredText"}
                    fontWeight={"light"}
                    className={'text-lg text-gray-500'}
                >
                    {description}
                </Paragraph>
            </div>
            <div className={faqContainerCn}>
                {faqItems && faqItems.map((faqItem) => {
                    return (
                        <FaqItem
                            {...faqItem}
                            key={faqItem.id}
                            children={faqItems.length}
                        />
                    );
                })}
            </div>
        </Container>
    );
}
