import React from 'react';
import Container from "components/core/Container";
import { IImage, IThemes, ITextSize } from "types/base";
import { Image } from "react-datocms";
import cn from "classnames";
import { lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";

export type ICompaniesList = {
    title: string,
    titleSize: ITextSize,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    logos: IImage[],
};

type Props = {
    theme: IThemes
};

export default function CompaniesList(props: Props & ICompaniesList) {
    const { theme, titleSize, lessSpaceTop, lessSpaceBottom } = props;

    const staticLogoContainerCn = cn(
        "py-26",
        "grid",
        "lg:grid-cols-6 lg:gap-x-24",
        "md:grid-cols-3 md:gap-x-16 md:gap-y-8",
        "grid-cols-2 gap-x-8 gap-y-4",
    );

    const imageContainerCn = cn(
        "h-12 mb-1",
    );

    const paragraphCn = cn(
        "text-gray-500 font-normal text-center",
        { "text-lg" : titleSize == "small" },
        { "text-4xl" : titleSize == "medium" }
);

    const containerCn = cn(

        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );


    return (
        <Container className={containerCn} theme={theme}>
            <div className={'flex justify-center opacity-40 mb-8'}>
                <p className={paragraphCn}>
                    {props.title}
                </p>
            </div>
            <div className={"flex relative mb-6"}>
                    <div className={staticLogoContainerCn}>
                        {props.logos.map((logo, index) => {
                            return (
                                <Image
                                    key={logo.id + index}
                                    data={logo.responsiveImage}
                                    className={imageContainerCn}
                                    pictureClassName={'object-contain'}
                                />
                            );
                        })}
                    </div>
            </div>
        </Container>
    );
}