import React, { useState } from "react";
import PlanParagraph from "components/elements/PlanParagraph";
import Text from "components/typography/Text";
import Icon from "components/elements/Icon";
import cn from "classnames";

export type IFaqItem = {
    id?: string,
    question?: string,
    answer?: any,
    children: number
};

export default function FaqItem(props: IFaqItem) {
    const { question, answer } = props;
    const [isExpanded, setExpanded] = useState(false);

    const iconCn = cn(
        'flex px-1 stroke-current',
        isExpanded ? "bg-gray-900 rotate-180" : 'bg-gray-400',
        "transition-all ease-out-in duration-200",
    );

    const questionCn = cn(
        "transition-all ease-out-in duration-500",
        'text-base lg:text-lg',
        isExpanded ? 'text-gray-700' : 'text-gray-400',
    );

    const answerCn = cn(
        'text-gray-500 font-light text-base mt-4 mr-6 lg:mr-10',
    );

    return (
        <div className={"flex flex-col border-b lg:pl-3 py-4"}>
            <div
                className={'flex flex-row items-center justify-between gap-6'}
                onClick={() => setExpanded(!isExpanded)}
            >
                <div className={isExpanded ? 'transition-all ease-out-in duration-500 cursor-pointer' : 'cursor-pointer'}>
                    {question && (
                        <Text className={questionCn} weight={'normal'}>
                            {question}
                        </Text>
                    )}
                </div>
                <div>
                    <Icon
                        url={"/static/icons/chevron-down.svg"}
                        className={iconCn}
                        size={"extra-small"}
                    />
                </div>
            </div>
            <div
                className={`overflow-hidden transform transition-height ease-in-out duration-500`}
                style={isExpanded ? { maxHeight: "50rem" } : { maxHeight: "0px" }}
            >
                {answer && (
                    <PlanParagraph
                        renderAs={"structuredText"}
                        className={answerCn}
                    >
                        {answer}
                    </PlanParagraph>)

                }
            </div>
        </div>

    );
}



