import Button from "components/elements/Button";
import React from "react";
import { ITextBlock } from "blocks/text/TextBlock";
import cn from "classnames";
import PreHeading from "components/elements/PreHeading";
import Heading from "components/elements/Heading";
import Link from "components/base/Link";
import { IButton, ILink } from "types/base";
import Paragraph from "components/elements/Paragraph";
import CtaForm from "blocks/cta/CtaForm";
import { IForm } from "blocks/cta/CtaBlock";
import nl2br from 'react-nl2br';

type Props = {
    className?: string;
    button?: IButton[]
};

export default function TextBlockContent(props: ITextBlock & Props) {
    const { title, description, preHeading, callToAction, align, theme, isHero, largeText, image, button } = props;

    const hasCallToAction = callToAction ? Object.keys(callToAction).length > 0 : false;

    const alignmentCn = cn(
        { 'place-items-center text-center mx-auto': align == 'center' },
        { 'lg:w-2/4': align == 'center' && !largeText },
        { 'lg:w-4/8 xl:w-3/4': align == 'center' && largeText },
        { 'flex flex-col my-auto': align == 'left' },
        { 'flex flex-col my-auto': align == 'right' },
    );

    const containerCn = cn(
        image && "justify-center",
        alignmentCn,
        props.className,
        'z-10'
    );

    const ctaFormCn = cn(
        "flex bg-white rounded-lg justify-between border border-purple-tint",
    );

    const ctaContainerCn = cn(
        "flex flex-col-reverse lg:flex-row mb-12 gap-3",
        { "justify-center": align == "center" }
    );

    const paragraphCn = cn(
        { "text-left": align == "left" },
        { "text-center": align == "center" },
        { "text-gray-500": props.theme == "default" },
        { "text-gray-500": props.theme == "gray" },
        { "text-gray-200": props.theme == "dark" },
    );

    const paragraphContainerCn = cn(
        "text-lg xl:text-xl",
        hasCallToAction ? 'mb-8 xl:mb-10' : "flex flex-col gap-y-8 mb-12",
        { "lg:w-4/5 xl:w-3/4 2xl:w-3/4": largeText },
        { "mx-auto": align == "center" }
    );

    const headingRenderAs = () => {
        if (isHero) {
            if (largeText) {
                return "h1-large";
            }
            return "h1";
        }
        return "h2";
    };

    const paragraphSize = () => {
        if (largeText) {
            return "extra-large";
        } else {
            if (isHero) {
                return "large";
            } else {
                return "medium";
            }
        }
    };

    return (
        <div className={"my-auto"}>
            <div className={containerCn}>
                {preHeading && (
                    <PreHeading
                        size={isHero ? "medium" : "small"}
                        theme={theme}
                    >
                        {preHeading}
                    </PreHeading>
                )}

                <Heading
                    renderAs={headingRenderAs()}
                    theme={theme}
                    className={`overflow-ellipsis`}
                >
                    {nl2br(title)}
                </Heading>
                <div className={paragraphContainerCn}>
                    <Paragraph
                        renderAs={"structuredText"}
                        size={paragraphSize()}
                        theme={theme}
                        alignment={align == 'right' ? 'left' : align}
                        fontWeight={"light"}
                        className={paragraphCn}
                    >
                        {description}
                    </Paragraph>
                </div>

                {hasCallToAction && (
                    <div className={ctaContainerCn}>
                        {callToAction && callToAction.map((cta) => {
                            if (cta.__typename == "FormRecord") {
                                const form = cta as IForm;

                                return (
                                    <CtaForm
                                        {...form}
                                        key={cta.id}
                                        className={ctaFormCn}
                                    />
                                );
                            }

                            const button = cta as IButton;

                            return (
                                <Link
                                    key={button.id}
                                    {...button.link[0]}
                                >
                                    <Button
                                        id={button.id}
                                        variant={button.variant}
                                        size={button.size}
                                        icon={button.icon}
                                    >
                                        {button.label}
                                    </Button>
                                </Link>
                            );
                        })}
                    </div>
                )}
                {button && (
                    <div className={ctaContainerCn}>
                        {button.map((button: IButton) =>
                            <div key={button.id}>
                                {button.link.map((link: ILink) => (
                                    <Link {...link} key={link.id}>
                                        <Button
                                            id={button.id}
                                            variant={button.variant}
                                            size={button.size}
                                            icon={button.icon}
                                        >
                                            {button.label}
                                        </Button>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
