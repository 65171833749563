import { IForm } from "blocks/cta/CtaBlock";
import Link from "components/base/Link";
import { IButton } from "types/base";
import Button from "components/elements/Button";
import cn from "classnames";
import { useState } from "react";

type Props = {
    className?: string
    type?: "partner" | "demo"
};

export default function CtaForm(props: Props & IForm) {
    const { placeholder, button } = props;
    const [inputValue, setInputValue] = useState("");

    const className = cn(
        props.className ?? ""
    );

    return (
        <div className={className}>
            <input
                type={"text"}
                name={"text"}
                placeholder={placeholder}
                onChange={(event) => setInputValue(event.target.value)}
                className={"pl-6 w-full lg:w-auto h-12 rounded-lg flex-1 font-normal outline-none my-auto text-base text-center lg:text-left lg:text-lg"}
                style={{ border: "none" }}
            />
            {button.map((button: IButton) =>
                <div
                    key={button.id + button.label}
                    className={"hidden h-0 lg:inline-flex lg:flex-shrink-0 lg:h-full m-1.5"}
                >
                    <Link {...props.button[0]?.link[0]} params={{ email: inputValue }}>
                        <Button
                            id={button.id}
                            variant={button.variant}
                            size={'large'}
                            color={button.color}
                            textColor={button.textColor}
                            icon={button.icon}
                            className={"justify-between w-full lg:text-xl text-base"}
                        >
                            {button.label}
                        </Button>
                    </Link>
                </div>
            )}
        </div>
    );
}