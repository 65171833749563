import React, { ReactNode } from "react";
import { textColorPreHeading } from "classes";

import cn from "classnames";
import { ITextSize, ITextWeight, IThemes } from "types/base";
import { ITextBlock } from "blocks/text/TextBlock";

type Props = {
    size?: ITextSize;
    theme?: IThemes;
    fontWeight?: ITextWeight;
    isHero?: ITextBlock;
    children: ReactNode;
    className?: string;
};

function PreHeading(props: Props) {
    const { isHero } = props;

    const sizeCn = cn(
        isHero ? "text-sm" : "text-base",
    );


    const preHeadingCn = cn(
        sizeCn,
        textColorPreHeading(props.theme),
        "font-medium mb-3"
    );

    return (
        <p className={preHeadingCn}>
            {props.children}
        </p>
    );
}

PreHeading.defaultProps = {
    size: "sm",
    theme: "default",
    fontWeight: "large"
};

export default PreHeading;